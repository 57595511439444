import { useCallback } from "react";
import { useAuthToken } from "./useApiToken";
import { ChatFileIndexType, CreateChatRequest, UpdateChatRequest, deleteAllUserChats, deleteChatFile, deleteUserChat, getAllUserChats, getUserChat, getUserChatFiles, newUserChat, updateUserChat, updateUserChatAndGetStreamResponse, updateUserChatTitle, uploadChatFile } from "../api/chat";
import { searchUserChatMessages } from "../api/search";
import { UpsertUserPromptRequest, deleteSharedPrompt, deleteUserPrompt, getAllSharedPrompts, getAllUserPrompts, getSharedPrompt, getUserPrompt, newSharedPrompt, newUserPrompt, updateSharedPrompt, updateUserPrompt } from "../api/prompts";
import { getLoggedOnUser, searchEntraUsers as searchAadUsers, getAllUsers, getUserById, addUser } from "../api/users";
import { addPersonaToUser, getAllPersonas, deleteUserFromPersona, updatePersonaDetails, ExtendedPersona, postNewPersona, NewPersona, deletePersona, getPersonaTrackedDocuments, getPersonaAssignedGroups, setPersonaAssignedGroups } from '../api/personas';
import { EntraGroup, searchEntraGroups } from "../api/groups";

// @ts-ignore
const API_BASE_URL = window.API_BASE_URL;


export const useApi = () => {
  const { acquireAuthToken, acquireSessionToken } = useAuthToken();

  const callApi = useCallback(
    async (method: string, path: string, data: object | FormData | null, abortController: AbortController) => {
      const headers = new Headers();

      // get auth token including user claims
      const authResult = await acquireAuthToken();

      if (!authResult) {
        console.error("Auth token not available.");
        return null;
      }

      headers.append("x-shyrka-aad-id-token", authResult.idToken);
      headers.append("x-shyrka-aad-access-token", authResult.accessToken);

      // get session token if in prod environment
      if (import.meta.env.PROD || import.meta.env.VITE_APP_API_BASE_URL == 'https://dev1.projectshyrka.co.uk') {
        console.log(`env: ${import.meta.env.VITE_APP_API_BASE_URL}, ${import.meta.env.PROD}`)
        const sessionToken = await acquireSessionToken(authResult);
        headers.append("X-ZUMO-AUTH", sessionToken);
      }

      let body = null;

      if (data) {
        if (data instanceof FormData) {
          body = data;
        } else {
          headers.append('Content-Type', 'application/json');
          body = JSON.stringify(data);
        }
      }

      try {
        return await fetch(API_BASE_URL + path, {
          method: method,
          headers: headers,
          body: body
        });
      } catch (apiError) {
        console.error("Failed to call backend API:", apiError);
        throw new Error("Failed to call backend API: " + apiError)
      }

    }, [acquireAuthToken]);

  const getApiAllUserChats = useCallback(
    async () => getAllUserChats(callApi),
    [callApi]
  );

  const getApiUserChat = useCallback(
    async (chatId: string) => getUserChat(callApi, chatId),
    [callApi]
  );

  const newApiUserChat = useCallback(
    async (createChatRequest: CreateChatRequest) => newUserChat(callApi, createChatRequest),
    [callApi]
  );

  const updateApiUserChat = useCallback(
    async (chatId: string, updateChatRequest: UpdateChatRequest) => updateUserChat(callApi, updateChatRequest, chatId),
    [callApi]
  );

  const updateApiUserChatTitle = useCallback(
    async (chatId: string, newTitle: string) => updateUserChatTitle(callApi, newTitle, chatId),
    [callApi]
  );

  const updateApiUserChatAndGetStream = useCallback(
    async (chatId: string, updateChatRequest: UpdateChatRequest) => updateUserChatAndGetStreamResponse(callApi, updateChatRequest, chatId),
    [callApi]
  );

  const deleteApiUserChat = useCallback(
    async (chatId: string) => deleteUserChat(callApi, chatId),
    [callApi]
  );

  const deleteApiAllUserChats = useCallback(
    async () => deleteAllUserChats(callApi),
    [callApi]
  );

  const searchApiUserChatMessages = useCallback(
    async (query: string) => searchUserChatMessages(callApi, query),
    [callApi]
  );

  const getApiAllUserPrompts = useCallback(
    async () => getAllUserPrompts(callApi),
    [callApi]
  );

  const getApiUserPrompt = useCallback(
    async (promptId: string) => getUserPrompt(callApi, promptId),
    [callApi]
  );

  const newApiUserPrompt = useCallback(
    async (upsertUserPromptRequest: UpsertUserPromptRequest) => newUserPrompt(callApi, upsertUserPromptRequest),
    [callApi]
  );

  const updateApiUserPrompt = useCallback(
    async (promptId: string, upsertUserPromptRequest: UpsertUserPromptRequest) => updateUserPrompt(callApi, upsertUserPromptRequest, promptId),
    [callApi]
  );

  const deleteApiUserPrompt = useCallback(
    async (promptId: string) => deleteUserPrompt(callApi, promptId),
    [callApi]
  );

  const getApiAllSharedPrompts = useCallback(
    async () => getAllSharedPrompts(callApi),
    [callApi]
  );

  const getApiSharedPrompt = useCallback(
    async (promptId: string) => getSharedPrompt(callApi, promptId),
    [callApi]
  );

  const newApiSharedPrompt = useCallback(
    async (upsertSharedPromptRequest: UpsertUserPromptRequest) => newSharedPrompt(callApi, upsertSharedPromptRequest),
    [callApi]
  );

  const updateApiSharedPrompt = useCallback(
    async (promptId: string, upsertUserPromptRequest: UpsertUserPromptRequest) => updateSharedPrompt(callApi, upsertUserPromptRequest, promptId),
    [callApi]
  );

  const deleteApiSharedPrompt = useCallback(
    async (promptId: string) => deleteSharedPrompt(callApi, promptId),
    [callApi]
  );

  const getApiLoggedOnUser = useCallback(
    async () => getLoggedOnUser(callApi),
    [callApi]
  );

  const uploadApiChatFile = useCallback(
    async (file: File, chatId: string, fileIndexType: ChatFileIndexType = 'summary') => uploadChatFile(callApi, file, chatId, fileIndexType),
    [callApi]
  );

  const getApiChatFiles = useCallback(
    async (chatId: string) => getUserChatFiles(callApi, chatId),
    [callApi]
  )

  const deleteApiChatFile = useCallback(
    async (chatId: string, fileId: string) => deleteChatFile(callApi, chatId, fileId),
    [callApi]
  )

  const getApiUsersSearch = useCallback(
    async (searchTerm: string) => searchAadUsers(callApi, searchTerm),
    [callApi]
  )

  const getApiAllUsers = useCallback(
    async () => getAllUsers(callApi),
    [callApi]
  )

  const getApiUserById = useCallback(
    async (userId: string) => getUserById(callApi, userId),
    [callApi]
  )

  const postAPIAddPersonaToUser = useCallback(
    async (userId: string, personaId: string) => addPersonaToUser(callApi, userId, personaId),
    [callApi]
  )

  const getApiAllPersonas = useCallback(
    async () => getAllPersonas(callApi),
    [callApi]
  )

  const deleteApiUserFromPersona = useCallback(
    async (userId: string, personaId: string) => deleteUserFromPersona(callApi, userId, personaId),
    [callApi]
  )

  const updateApiPersonaDetails = useCallback(
    async (persona: ExtendedPersona) => updatePersonaDetails(callApi, persona),
    [callApi]
  )

  const postApiCreateUser = useCallback(
    async (email: string) => addUser(callApi, email),
    [callApi]
  )

  const postApiNewPersona = useCallback(
    async (persona: NewPersona) => postNewPersona(callApi, persona),
    [callApi]
  )

  const deleteApiPersona = useCallback(
    async (personaId: string) => deletePersona(callApi, personaId),
    [callApi]
  )

  const getApiPersonaTrackedDocs = useCallback(
    async (personaId: string) => getPersonaTrackedDocuments(callApi, personaId),
    [callApi]
  )

  const getApiGroupsSearch = useCallback(
    async (searchTerm: string) => searchEntraGroups(callApi, searchTerm),
    [callApi]
  )

  const getApiPersonaAssignedGroups = useCallback(
    async (personaId: string) => getPersonaAssignedGroups(callApi, personaId),
    [callApi]
  )

  const postApiSetPersonaAssignedGroups = useCallback(
    async (personaId: string, groups: EntraGroup[]) => setPersonaAssignedGroups(callApi, personaId, groups),
    [callApi]
  )



  return {
    callApi,
    deleteApiAllUserChats,
    deleteApiChatFile,
    deleteApiPersona,
    deleteApiSharedPrompt,
    deleteApiUserChat,
    deleteApiUserFromPersona,
    deleteApiUserPrompt,
    getApiAllPersonas,
    getApiAllSharedPrompts,
    getApiAllUserChats,
    getApiAllUserPrompts,
    getApiAllUsers,
    getApiChatFiles,
    getApiGroupsSearch,
    getApiLoggedOnUser,
    getApiPersonaAssignedGroups,
    getApiPersonaTrackedDocs,
    getApiSharedPrompt,
    getApiUserById,
    getApiUserChat,
    getApiUserPrompt,
    getApiUsersSearch,
    newApiSharedPrompt,
    newApiUserChat,
    newApiUserPrompt,
    postAPIAddPersonaToUser,
    postApiCreateUser,
    postApiNewPersona,
    postApiSetPersonaAssignedGroups,
    searchApiUserChatMessages,
    updateApiPersonaDetails,
    updateApiSharedPrompt,
    updateApiUserChat,
    updateApiUserChatAndGetStream,
    updateApiUserChatTitle,
    updateApiUserPrompt,
    uploadApiChatFile,
  }
};
