import { FC, useContext, useEffect, useReducer, useRef, useState } from 'react';

import { useCreateReducer } from '../../hooks/useCreateReducer';

import { getSettings, saveSettings } from '../../utils/app/settings';

import { Settings } from '../../types/settings';

import HomeContext from '../../pages/chatv2/Chatv2.context';
import { useApi } from '../../hooks/useApi';
import { get } from 'http';
import { ChatFileIndex, ChatFileIndexType, ChatResponse } from '../../api/chat';
import { IconTrash, IconUpload, IconX } from '@tabler/icons-react';
import { Spinner } from '@fluentui/react';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ChatFilesDialog: FC<Props> = ({ open, onClose }) => {
  const settings: Settings = getSettings();

  const { state, dispatch } = useCreateReducer<Settings>({
    initialState: settings,
  });

  const { dispatch: homeDispatch, state: { selectedChatSummary: selectedChat } } = useContext(HomeContext);

  const { uploadApiChatFile, getApiUserChat, deleteApiChatFile, getApiChatFiles } = useApi();

  const modalRef = useRef<HTMLDivElement>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  const [currentChat, setCurrentChat] = useState<ChatResponse | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedIndexType, setSelectedIndexType] = useState<ChatFileIndexType>("summary");
  const [filesDeleting, setFilesDeleting] = useState<string[]>([]);
  const [processingFiles, setProcessingFiles] = useState<ChatFileIndex[]>([]);

  const isFileDeleting = (fileId: string) => {
    return filesDeleting.includes(fileId);
  }

  const handleFileIndexTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedIndexType(event.target.value as ChatFileIndexType);
  }

  useEffect(() => {
    if (selectedChat) {
      const getChat = async () => {
        const chat = await getApiUserChat(selectedChat.id);
        setCurrentChat(chat);
      }
      getChat();
    }
  }, [selectedChat]);

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.files && event.target.files.length) {
      setSelectedFile(event.target.files[0]);
      // await onFileUpload();
    }
  };

  const handleFileDelete = async (fileId: string) => {
    if (currentChat) {
      const fileToDelete = currentChat.file_indexes.filter(f => f.id === fileId);

      if (fileToDelete) {
        // update the file deleting state to include the file id in the list
        setFilesDeleting([...filesDeleting, fileId]);

        const newFileIndexes = currentChat.file_indexes.filter(f => f.id !== fileId);
        await deleteApiChatFile(currentChat.id, fileId);
        const updatedChat = { ...currentChat, file_indexes: newFileIndexes };
        setCurrentChat(updatedChat);

        if (updatedChat.file_indexes.length === 0) {
          homeDispatch({ field: 'chatHasFiles', value: false });
        } else {
          homeDispatch({ field: 'chatHasFiles', value: true });
        }
      }

      // remove the file id from the file deleting state
      setFilesDeleting(filesDeleting.filter(f => f !== fileId));

      // Add your file deletion logic here.
      console.log('Deleting file:', fileToDelete);
    }
  };

  const onFileUpload = async () => {
    if (selectedFile) {
      try {
        if (selectedChat) {
          await uploadApiChatFile(selectedFile, selectedChat.id, selectedIndexType)
          const chat = await getApiUserChat(selectedChat.id);
          setCurrentChat(chat);
          homeDispatch({ field: 'chatHasFiles', value: true });
        }
      } catch (error) {

        alert(`Error: ${(error as Error).message}`);
      }
    } else {
      alert('Please select a file before uploading.');
    }

    handleReset();
  };

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        window.addEventListener('mouseup', handleMouseUp);
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      window.removeEventListener('mouseup', handleMouseUp);
      onClose();
    };

    window.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, [onClose]);

  useEffect(() => {
    const pollFileProcessingStatus = async () => {

      if (currentChat) {
        const pendingFiles = currentChat.file_indexes.filter(f => f.status === "pending");

        if (pendingFiles.length > 0) {
          const updatedFiles = await getApiChatFiles(currentChat.id);

          const updatedChat: ChatResponse = {
            ...currentChat,
            file_indexes: updatedFiles
          }

          setCurrentChat(updatedChat);
        }
      }
    };

    const intervalId = setInterval(pollFileProcessingStatus, 1000);

    return () => clearInterval(intervalId);
  }, [currentChat, getApiChatFiles]);

  // Render nothing if the dialog is not open.
  if (!open) {
    return <></>;
  }

  // Render the dialog.
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="fixed inset-0 z-10 overflow-hidden">
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          />

          <div
            ref={modalRef}
            className="dark:border-netural-400 inline-block max-h-[400px] transform overflow-y-auto rounded-lg border border-gray-300 bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all dark:bg-[#202123] sm:my-8 sm:max-h-[600px] sm:w-full sm:max-w-xl sm:p-6 sm:align-middle"
            role="dialog"
          >
            <div className="text-lg pb-4 font-bold text-black dark:text-neutral-200">
              {'Chat files'}
            </div>

            <p className='pt-1 pb-4 font text-black dark:text-neutral-200'>
              Upload files to your chat to provide extra context to this Shyrka conversation.  <span className="font-bold">This will disable the ability to select a specific persona.</span>
            </p>

            {(currentChat && currentChat.file_indexes.length > 0) ? (
              <div>
                <ul>
                  {currentChat.file_indexes.map((fileIndex, index) => (
                    <li className="pt-2 pb-3 flex items-center" key={index}>

                      {(fileIndex.status === "pending" || isFileDeleting(fileIndex.id)) && (
                        <Spinner />
                      ) || (
                          <button
                            type="button"
                            className=""
                            onClick={() => {
                              handleFileDelete(fileIndex.id);
                            }}
                          >
                            <IconTrash className="text-red-700" />
                          </button>
                        )
                      }

                      <span className="pl-3 text-black dark:text-neutral-200" >{fileIndex.file_name}</span>

                      {fileIndex.status == "failed" && (
                        <span className="pl-3 text-red-700">{'Failed to process file. Please delete and try again.'}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}

            <div className="pt-6 pb-2">
              <input type="file" ref={fileInputRef} onChange={onFileChange} accept={".pdf,.doc,.docx,.txt,.pptx,.ppt,.json"} className="text-black dark:text-neutral-200" />
            </div>

            <div className="pt-2 pb-3 flex items-center">
              <button
                type="button"
                className="w-5/12 mr-auto px-4 py-2 mt-6 border rounded-lg shadow border-neutral-500 text-neutral-900 hover:bg-neutral-100 focus:outline-none dark:border-neutral-800 dark:border-opacity-50 dark:bg-white dark:text-black dark:hover:bg-neutral-300"
                onClick={() => {
                  onFileUpload();
                }}
              >
                {'Upload'}
              </button>
              <button
                type="button"
                className="w-5/12 ml-auto px-4 py-2 mt-6 border rounded-lg shadow border-neutral-500 text-neutral-900 hover:bg-neutral-100 focus:outline-none dark:border-neutral-800 dark:border-opacity-50 dark:bg-white dark:text-black dark:hover:bg-neutral-300"
                onClick={() => {
                  onClose();
                }}
              >
                {'Exit'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
